<script>
  import Header from "./Header.svelte";
</script>

<div>
  <Header />
  <main>
    <h2>API Development and Devops</h2>
    <p>I take complicated data and make simple APIs on secure platforms.</p>
    <p>With over a decade of experience designing and developing platforms for startups, NGOs, and multi-national enterprises, I&rsquo;ve probably worked in your problem space.</p>
    <h3>Interested in working with me?</h3>
    <p>Drop me a line on <a href="mailto:hey@joshthewanderer.com">hey@joshthewanderer.com</a> and I&rsquo;ll get back to you.</p>
  </main>
</div>

<style>
  main {
    margin: 1rem 2rem;
  }

  a {
    color: var(--color-accent);
    display: inline-block;
    position: relative;
    text-decoration: none;
  }

  a:after {
    background-color: var(--color-accent);
    bottom: 2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    width: 100%;
  }

  a:hover:after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    margin: 0 0 1rem;
  }

  h2, h3 {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.25;
    margin: 1rem 0;
  }

  h3 {
    font-size: 2.5rem;
  }

</style>
