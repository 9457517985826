<header>
  <h1>Josh Rose</h1>
</header>

<style>
  header {
    padding: 1rem 2rem;
  }

  h1 {
    color: var(--color-accent);
    font-size: 6rem;
    font-weight: 900;
    line-height: 1.25;
    margin: 0;
  }
</style>
